#deleted-post {
  max-width: 600px;
  font-family: 'Courier New', Courier, monospace;
  margin: 0 auto;
  padding: 30px;
  background-color: #d84949;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
}

.name-post {
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 10px;
}

.description-post {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: justify;
}

.author, .date, .id {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
}

button {
  display: inline-block;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}

button:hover {
  background-color: #d32f2f;
}

button:active {
  background-color: #b71c1c;
}

button:last-child {
  margin-right: 0;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

#deleted-post button {
  display: block;
  margin: 0 auto;
}
