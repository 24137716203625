body {
  font-family: Arial, sans-serif;
  background-color: #f7f9fc;
  margin: 0;
  padding: 0;
}

.page-title {
  text-align: center;
  margin-top: 20px;
  font-size: 24px;
  color: #333;
}

.alunos-table {
  width: 80%;
  margin: 40px auto;
  border-collapse: collapse;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.alunos-table th, .alunos-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
}

.alunos-table th {
  background-color: #f2f2f2;
}

.alunos-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ddd;
}

.editBtn {
  background-color: #4CAF50;
  color: white;
}

button:nth-child(1) {
  color: #fff;
  margin-right: 8px;
}

/* button:nth-child(2) {
  background-color: #f44336;
  color: #fff;
} */
/* 
button:nth-child(2):hover {
  background-color: #e53935;
} */

/* Admission.css */
.MuiTab-root {
  color: #2a95b6 !important;
}

.MuiTab-root.Mui-selected {
  color: #2a95b6 !important;
}

.MuiTabs-indicator {
  background-color: #2a95b6 !important;
}
