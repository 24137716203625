#login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 0 2rem;
  margin-bottom: 25rem ;
}

#login .form {
  background-color: #f1f1f1;
  width: 100%;
  max-width: 480px;
  padding: 5rem; /* Ajuste no padding para um pouco mais de espaçamento */
}

#login .field {
  padding-bottom: 0.5rem; /* Corrigido o valor do padding-bottom */
}

#login .field label {
  display: block;
}

#login .field input {
  width: 100%; 
  padding: 0.5rem; /* Adicionado padding para melhor visualização */
  margin-top: 0.25rem; /* Espaçamento entre label e input */
  box-sizing: border-box; /* Para garantir que o padding não ultrapasse a largura do input */
}

#login .actions {
  text-align: center;
  margin-top: 1rem; /* Ajustado o margin-top para um pouco mais de espaçamento */
}

#login .actions button {
  width: 100%;
  max-width: 200px; /* Definindo uma largura máxima para os botões */
  padding: 0.5rem; /* Adicionando padding para melhor visualização */
  margin: 0.25rem 0; /* Espaçamento entre os botões */
  box-sizing: border-box; /* Para garantir que o padding não ultrapasse a largura do botão */
}
