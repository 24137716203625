/* Estilos específicos para a página Nossa Escola */

/* Container principal */
.our-school-container {
  padding-top: 50px;
  padding-bottom: 70px;
  margin-bottom: 50px;
}

/* Cabeçalho */
.our-school-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #01bdde;
}

.our-school-header p {
  font-size: 1rem;
  color: #01bdde;
}

/* Papel */
.our-school-paper {
  padding: 20px;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Galeria de Fotos */
.our-school-paper ul {
  padding-left: 20px;
}

.our-school-paper ul li {
  margin-bottom: 10px;
}

/* Cards */
.our-school-card {
  border-radius: 8px;
  overflow: hidden;
}

.our-school-card-media {
  object-fit: cover;
}

.our-school-card-content {
  text-align: center;
}

@media (max-width: 768px) {
  .our-school-header h1 {
    font-size: 2rem;
  }

  .our-school-header p {
    font-size: 0.9rem;
  }
}

.our-school-container {
  padding: 20px;
}

.our-school-header {
  margin-bottom: 40px;
}

.our-school-paper {
  padding: 20px;
  transition: box-shadow 0.3s ease-in-out;
}

.our-school-paper:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.our-school-list {
  padding-left: 20px;
}

.our-school-list li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .our-school-container {
    padding: 10px;
  }

  .our-school-header {
    margin-bottom: 20px;
  }

  .our-school-paper {
    padding: 15px;
  }
}
