#new-post {
  background-position: center center;
  color: #fc0000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  flex-basis: 50%;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 2rem;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.input-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}

label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

input,
textarea {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

button[type="submit"] {
  background-color: #d41e1e;
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

button[type="submit"]:hover {
  background-color: #0b5ed7;
}

#turma {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  background-color: #f2f2f2;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  form {
    padding: 1rem;
  }
}
