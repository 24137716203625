.post-content{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card{
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #f44336;
  border-style: none;
  border-radius: 0.5vh;
  flex-wrap: wrap;
  text-align: center;
  padding: 5vh;
  margin: 1vh;
  width: 180vh;
  text-transform: capitalize;
  cursor: pointer;
}

.card:hover{
  align-items: center;
  justify-content: center;
  background-color: #d71e62;
  border-style: none;
  border-radius: 0.5vh;
  flex-wrap: wrap;
  text-align: center;
  padding: 5vh;
  margin: 1vh;
  width: 180vh;
  text-transform: capitalize;
  cursor: pointer;
}

.title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: white;
}

.name-post {
  font-size: 20px;
  font-weight: bold;
  color: white;
}


.description-post {
  font-size: 10px;
  color: white;
}

.author{
  font-size: 15px;
  color: white;
}

.img{
  align-items: center;
  border-style:solid;
  border-radius: 5px;
  margin: 5px;
  width: 48vh;
}

.img:hover{
  border: 5px solid rgb(110, 21, 170);
  box-shadow: 0 5px 15px rgba(0,0,0,0.6);
  transform: scale(1.1); 
}

.details{
  align-items: center;
  border: solid;
  color: white;
  display: flex;
  justify-content: center;
  margin-left: 30vh;
  margin-right: 30vh;
  margin-bottom: 10vh;
  margin-block-end: 20vh;
  text-align: center;
}

.img-details {
  align-items: center;
  border-style:solid;
  border-radius: 5px;
  margin: 5px;
  width: 280px;
}

.h2-resumo{
  align-items: center;
  border: solid;
  color: white;
  display: flex;
  justify-content: center;
  margin-left: 30vh;
  margin-right: 30vh;
  margin-bottom: 10vh;
  margin-block-end: 20vh;
  text-align: center;
}

.button-goback{
  border:1px solid #25692A;
  border-radius:4px;
  display:flex;
  cursor:pointer;
  margin: 5px;
  font-family:Verdana;
  font-weight:bold;
  font-size:13px;
  padding:6px 10px;
  text-decoration:none;
} 
   
.button-goback-vm {
  border-color:#f61f0e;
  background:linear-gradient(to bottom, #fca8a1 5%, #f61f0e 100%);
  box-shadow:inset 0px 1px 0px 0px #fff4f3;
  color:#fff;
  text-shadow:0px 1px 0px #528009; 
}
  
.button-goback:hover {
  background:linear-gradient(to bottom, #f61f0e 5%, #fca8a1 100%);
}
  
.button-goback:active {
  position:relative;
  top:2px;
}    

.linkedin-bottom-btn{
  align-items: center;
  display: flex;
  margin: 10vh;
  text-align: center;
  width: 3px;
}

.github-bottom-btn{
  align-items: center;
  display: flex;
  margin: 10vh;
  text-align: center;
  width: 3px;
}

.h2-detalhes{
  align-items: center;
  display: flex;
  margin: 10vh;
  text-align: center;
}

.date {
  font-size: 10px;
  color: white;
}

@media screen and (max-width: 768px) {
.card {
width: 90%;
margin: 1vh auto;
}

.details {
margin: 5vh auto;
}

.h2-resumo {
margin: 5vh auto;
}

.linkedin-bottom-btn, .github-bottom-btn {
margin: 5vh auto;
}
}

@media screen and (max-width: 320px) {
  .card {
    width: 90%;
    margin: 1vh auto;
  }
    
  .details {
    margin: 5vh auto;
  }
    
  .h2-resumo {
    margin: 5vh auto;
  }
    
  .linkedin-bottom-btn, .github-bottom-btn {
    margin: 5vh auto;
  }
    
  .h2-detalhes {
    margin: 5vh auto;
  }
    
  .img-details {
    width: 90%;
  }
    
  .img {
    width: 90%;
  }
    
  .button-goback {
    margin: 5vh auto;
  }
    
  .button-goback-vm {
    margin: 5vh auto;
  }
    
  .button-goback:hover {
    margin: 5vh auto;
  }
    
  .button-goback:active {
    margin: 5vh auto;
  }
  
}

@media screen and (max-width: 375px) {
  .card {
    width: 90%;
    margin: 1vh auto;
  }
    
  .details {
    margin: 5vh auto;
  }
    
  .h2-resumo {
    margin: 5vh auto;
  }
    
  .linkedin-bottom-btn, .github-bottom-btn {
    margin: 5vh auto;
  }
    
  .h2-detalhes {
    margin: 5vh auto;
  }
    
  .img-details {
    width: 90%;
  }
    
  .img {
    width: 90%;
  }
    
  .button-goback {
    margin: 5vh auto;
  }
    
  .button-goback-vm {
    margin: 5vh auto;
  }
    
  .button-goback:hover {
    margin: 5vh auto;
  }
    
  .button-goback:active {
    margin: 5vh auto;
  }
  
  
}

@media screen and (max-width: 480px) {
  .card {
    width: 90%;
    margin: 1vh auto;
  }
    
    
}
  